const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}
const validateEmail = (email) => {
  if (!isValidEmail(email)) {
    return false;
  }
  if (/(gmail|yahoo|hotmail)\.com$/.test(email)) {
    return false;
  }

  return true;
}

const validatePhone = (phone) => {
  return String(phone)
    .toLowerCase()
    .match(
      /^(\+\d{1,3}[- ]?)?\d{10}$/
    );
}

const convertNumberToStandardFormat = (number) => {
  return String(number)
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}

export { validateEmail, validatePhone, convertNumberToStandardFormat }
