import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

class Assessment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            radio: '',
            error: ''
        }
    }
    onChangeInput = (e) => {
        this.setState({
            radio: e.target.value,
            error: ''
        })
    }
    onClickButton = () => {
        if (this.state.radio === '') {
            console.log("error")
            this.setState({ error: 'Please select an option' });
            return
        }
    }
    render() {
        let answerError = this.state.error ? <div className="text-danger custom-margin-form-control">{this.state.error}</div> : null;
        return (
            <div>
                <Navbar />
                <div className="container">
                    <div className="w-full mx-auto mt-3 mb-3" style={{ width: "100%" }}>
                        <p className="text-center theme-p-color"><strong>11. Who drives digital transoformation agenda in the Organization</strong>	&nbsp;
                            <span className='custom_tooltip black'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#000" viewBox="0 0 16 16">
                                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>

                                <span class="tooltiptext">Tooltip text</span>
                            </span>
                        </p>

                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                value="option1"
                                onChange={this.onChangeInput}
                            />
                            <label className="mx-2 form-check-label" htmlFor="flexRadioDefault1">
                                a. There is no single ownership for digital transofmation within organization. It is ad-hoc
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                value="option1"
                                onChange={this.onChangeInput}
                            />
                            <label className="mx-2 form-check-label" htmlFor="flexRadioDefault1">
                                b. IT team drives the digital transformation agenda
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                value="option1"
                                onChange={this.onChangeInput}
                            />
                            <label className="mx-2 form-check-label" htmlFor="flexRadioDefault1">
                                c. Digital Tranformation is owned and driven by respective departments.
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                value="option1"
                                onChange={this.onChangeInput}
                            />
                            <label className="mx-2 form-check-label" htmlFor="flexRadioDefault1">
                                d. CEO drives the digital transformation in the organization
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1"
                                value="option1"
                                onChange={this.onChangeInput}
                            />
                            <label className="mx-2 form-check-label" htmlFor="flexRadioDefault1">
                                e. CDO, with dedicated PL, reporting to CEO drives the digital tranformation agenda
                            </label>
                        </div>
                        {answerError}
                    </div>
                    <br />
                    <div className='form-group mt-3'>
                        <label htmlFor="addText">Add Text</label>
                        <input type="text" className='form-control' placeholder='Add Text' id="addText" />
                    </div>
                    <br />
                    <div className='row mt-3'>
                        <div className='col-md-3'>
                            <Link to="/assessment" className="btn btn-primary mt-3 mb-auto float-end">Back</Link>
                        </div>
                        <div className='col-md-3'>
                            <button className='btn btn-primary mt-3' onClick={this.onClickButton}>Next</button>
                        </div>
                        <div className='col-md-3'>
                            <div class="circle-wrap">
                                <div class="circle">
                                    <div class="mask full">
                                        <div class="fill"></div>
                                    </div>
                                    <div class="mask half">
                                        <div class="fill"></div>
                                    </div>
                                    <div class="inside-circle"> 75% </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <button className='btn btn-primary mt-3'>Logout</button>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Assessment;
