import React from 'react';
import Loader from '../components/Loader';
import { validateEmail } from '../utils';
import { Link } from 'react-router-dom';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            email: '',
            otp: '',
            error: true,
            otpSent: false,
            otpVerified: false,
            errors: {},
            timer: 0,
        }
    }
    onChangeInput = (e) => {
        // validate the email.
        if (e.target.name === 'email') {
            // validate the email.
            let val = e.target.value;
            if (val !== '' && !validateEmail(val)) {
                this.setState({
                    errors: {
                        email: 'Please enter a valid email address.'
                    },
                    error: true,
                })
                return;
            }
        }

        this.setState({
            errors: {},
            error: false,
            [e.target.name]: e.target.value
        })
    }
    timerManager = () => {
        let timer = this.state.timer;
        if (timer > 0) {
            setTimeout(() => {
                this.setState({
                    timer: timer - 1
                },  () => {
                    this.timerManager();
                })
            }, 1000);
        }
    }
    onSubmitOTP = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log("clicked")
        this.setState({
            loading: true,
            timer: 30,
        }, () => {
            this.timerManager();
        })
    }
    render() {

        let EmailError = this.state.errors.email ? <div className="text-danger">{this.state.errors.email}</div> : null;
        // let otpButtonText = 'Sent OTP'
        // if (this.state.timer > 0)
        //     otpButtonText = `${this.state.timer}`
        // else
        //     otpButtonText = 'Resend OTP'
        return (
            <div className='form-main'>
                <form className='form mt-3'>
                    <div className='row content'>
                        <h3>Login</h3>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input
                                    type='email' className='form-control'
                                    id='email'
                                    name="email"
                                    onChange={this.onChangeInput}
                                />
                                { EmailError }
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <label htmlFor='otp'>OTP</label>
                                <input
                                    type='number' className='form-control'
                                    id='otp'
                                    name="otp"
                                    onChange={this.onChangeInput}
                                    disabled={!this.state.otpVerified}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <div className='col-md-6'>
                            <div className='form-group'>
                                <button
                                    type='submit' className='btn btn-primary'
                                    onClick={
                                        this.onSubmitOTP}
                                    disabled={this.state.loading || this.state.email === '' || this.state.error || this.state.timer > 0}
                                >Verify</button>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-3'>
                        <Loader loading={this.state.loading} />
                    </div>
                    <div className='text-center mt-3'>
                        <Link to='/register' className='btn btn-primary'>Login</Link>
                    </div>
                </form>
            </div>
        )
    }
}

export default Login;