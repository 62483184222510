import React from 'react';
import { Routes, Route } from "react-router-dom";
import Login from './Login';
import Registration from './Registration';
import Foreword from './Foreword';
import Introduction from './Introduction';
import Assessment from './Assessment';
import CompanyDetails from './CompanyDetails';
import NotFound from './site/NotFound';

class App extends React.Component {
    render() {
        return (
            <div>
                <Routes>
                    <Route path="/" element={<Login />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Registration />} />
                    <Route path="/foreword" element={<Foreword />} />
                    <Route path="/introduction" element={<Introduction />} />
                    <Route path="/assessment" element={<Assessment />} />
                    <Route path="/company-details" element={<CompanyDetails />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
            </div>

        )
    }
}

export default App;