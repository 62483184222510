import React from 'react';

class Footer extends React.Component {
    render() {
        return (
            <div className="mt-3 mb-3">
                <br /><br />
                <p className="text-center">@ copyright 2022 : Cci Lab </p>
            </div>
        )
    }
}

export default Footer
