import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';

class Introduction extends React.Component {
    render() {
        return (
            <div>
                <Navbar />
                <div className="container mb-3">
                    <p>
                        This is a short assignment which will help to gauge your Organization Digital Maturity along 4 axies:

                        <ol className='mt-3 mb-3'>
                            <li>Digital Vision &amp; Strategy</li>
                            <li>Data &amp; Analytics</li>
                            <li>Organization &amp; Skills</li>
                            <li>Process &amp; Technology</li>
                        </ol>

                        <span className=''>
                        There are a series of multiple-choice questions. At any time you can navigate to the previous screen using "Back" Button You may exit the assessment at any stage and you can retry where you left off. 
                        </span>

                        <span className='mt-3'>
                            The assessment is divided into 5 sections:

                            <ol className='mt-3 mb-3'>
                                <li>Introduction</li>
                                <li>Basic Details</li>
                                <li>Company Details</li>
                                <li>Overall Digital Maturity</li>
                                <li>Functional Maturity</li>
                            </ol>
                        </span>

                        <span className='mt-3'>
                            At the end of the assessment a Digital Score is automatically generated by the tool. You will recieve an e-book which gives you maturity score and also benchmarks your score to your industry average on your official e-mail. If you have any further questions please feel free to send your query to John@domain.com
                        </span>

                    </p>
                    <div className="text-center">
                        <Link to="/assessment" className="btn btn-primary mt-3 mx-auto">Start Assessment</Link>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Introduction;
