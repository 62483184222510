import React from 'react';
import { NavLink } from "react-router-dom";
import Logo from '../assets/images/logo.png';

class Navbar extends React.Component {
  navClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }
  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-navbar">
          <div className="container-fluid">
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="container collapse navbar-collapse" id="navbarTogglerDemo01">
              <NavLink 
                className="navbar-brand"
                to="#!"
                activeClassName="active"
                exact 
              >
                <img src={Logo} alt="logo" style={{
                  width: "60px",
                  height: "60px"
                }} />
              </NavLink>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink 
                    className="nav-link nav-link-color left-nav"
                    to="/introduction"
                    activeClassName="active"
                    exact
                    onClick={this.navClick}
                  >Introduction</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink 
                    className="nav-link nav-link-color"
                    to="/register"
                    activeClassName="active"
                    exact
                    onClick={this.navClick}
                  >Basic Details</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink 
                    className="nav-link nav-link-color"
                    to="/company-details"
                    activeClassName="active"
                    exact
                    onClick={this.navClick}
                  >Company Details</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink 
                    className="nav-link nav-link-color"
                    to="/assessment"
                    activeClassName="active"
                    exact
                    onClick={this.navClick}
                  >Overall Digial Maturity</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink 
                    className="nav-link nav-link-color"
                    to="/unknown"
                    activeClassName="active"
                    exact
                    onClick={this.navClick}
                  >Functional Maturity</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink 
                    className="nav-link nav-link-color logout"
                    to="/unknown"
                    activeClassName="active"
                    exact
                    onClick={this.navClick}
                  >Logout</NavLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <br />
        {/* <div className="mt-2 mb-2 w-80 mx-auto" style={{width: "80%"}}>
          <hr />
        </div> */}

      </div>
    )
  }
}

export default Navbar
