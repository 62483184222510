import React from "react";
import { Link } from "react-router-dom";

class NotFound extends React.Component {
    componentWillMount() {
        document.title = "404 Not Found";
    }
    render() {
        return (
            <div>
                <div class="container-fluid mt-6 py-10">
                    <div class="row">
                        <div class="col">
                            <div class="">
                                <div class="">
                                    <div class="row">
                                        <div class="col-12">
                                            <h1 class="mb-0 text-center">
                                                404 Not found
                                            </h1>
                                            <p class="text-center">
                                                Sorry, the page you looking for
                                                deleted or not exists.
                                                <br />
                                                <br />
                                                <Link
                                                    to="/"
                                                    class="btn btn-primary float-center text-center"
                                                >
                                                    Home
                                                </Link>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
