import React from 'react';
import Loader from '../components/Loader';
import { validateEmail } from '../utils';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { Link, Navigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

class Registration extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            form: {
                name: '',
                company_name: '',
                company_url: '',
                designation: '',
                official_email: '',
                mob: '',
                country: 'India',
            },
            error: true,
            otpSent: false,
            otpVerified: false,
            errors: {},
            timer: 0,
            redirect: false,
        }
    }

    onChangePhone = (e, country ) => {
        this.setState({
            form: {
                ...this.state.form,
                mob: e,
                country: country.name
            }
        })
    }

    onValidate = (key, value) => {
        if (key === 'email' || key === 'name') {
            if (key === 'email' && value !== '' && !validateEmail(value)) {
                this.setState({
                    errors: {
                        
                        email: 'Please use official email id.'
                    },
                    error: true,
                })
                return;
            } else if (key === 'name' && value === '') {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        name: 'Please enter a valid name.'
                    },
                    error: true,
                })
                return;
            }
        }

        if (key === 'company_name') {
             if (value === '' || value.length < 3) {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        company_name: 'Please enter a valid company name.'
                    },
                    error: true,
                })
                return;
            }
        } 

        this.setState({
            errors: {},
            error: false,
            form: {
                ...this.state.form,
                [key]: value,
            }
        })

    }
    onChangeInput = (e) => {
        let key = e.target.name;
        let value = e.target.value;
        this.onValidate(key, value)

    }
    onCickHandler = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let error = false;
        let errors = {};
        if (this.state.form.name === '') {
            error = true;
            errors.name = 'Please enter a valid name.'
        }
        if  (this.state.form.company_name === '') {
            error = true;
            errors.company_name = 'Please enter a valid company name.'
        }

        // email.
        if (this.state.form.email === '' || !validateEmail(this.state.form.email)) {
            error = true;
            errors.email = 'Please use official email id.'
        }
        if (error) {
            this.setState({
                error: true,
                errors,
            })
            return;
        }
        // check if this.state.errors is empty object
        if (error === false) {
            // redirect to otp page
            this.setState({
                redirect: true,
            })
        }
    }

    render() {
        if (this.state.redirect) {
            return <Navigate to="/company-details" />
        }
        let EmailError = this.state.errors.email ? <div className="text-danger custom-margin-form-control">{this.state.errors.email}</div> : null;
        let NameError = this.state.errors.name ? <div className="text-danger custom-margin-form-control">{this.state.errors.name}</div> : null;
        let companyNameError = this.state.errors.company_name ? <div className="text-danger custom-margin-form-control">{this.state.errors.company_name}</div> : null;
        let MobError = this.state.errors.mob ? <div className="text-danger custom-margin-form-control">{this.state.errors.mob}</div> : null;

        return (
            <div>
                <Navbar />
                <div className='container mt-3 mb-3'>
                    <form className='mx-auto w-100 reg'>
                        <div className='row g-3 align-items-center mt-3'>
                            <div className="col-md-2">
                                <label htmlFor='email' className="col-form-label"><strong>Name <span className="text-danger"> *</span></strong></label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type='name'
                                    className='form-control custom-margin-form-control'
                                    id='name'
                                    name="name"
                                    onChange={this.onChangeInput}
                                />
                                {NameError}
                            </div>
                        </div>

                        <div className='row g-3 align-items-center mt-2'>
                            <div className="col-md-2">
                                <label htmlFor='cname' className="col-form-label"><strong>Company Name  <span className="text-danger"> *</span></strong></label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type='cname'
                                    className='form-control custom-margin-form-control'
                                    id='cname'
                                    name="company_name"
                                    onChange={this.onChangeInput}
                                />
                                {companyNameError}
                            </div>
                        </div>

                        <div className='row g-3 align-items-center mt-2'>
                            <div className="col-md-2">
                                <label htmlFor='curl' className="col-form-label"><strong>Company URL</strong></label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type='url'
                                    className='form-control custom-margin-form-control'
                                    id='curl'
                                    name="company_url"
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>

                        <div className='row g-3 align-items-center mt-2'>
                            <div className="col-md-2">
                                <label htmlFor='designation' className="col-form-label"><strong>Designation</strong></label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type='text'
                                    className='form-control custom-margin-form-control'
                                    id='designation'
                                    name="designation"
                                    onChange={this.onChangeInput}
                                />
                            </div>
                        </div>

                        <div className='row g-3 align-items-center mt-2'>
                            <div className="col-md-2">
                                <label htmlFor='email' className="col-form-label"><strong>Office E-Mail <span className="text-danger"> *</span></strong></label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type='email'
                                    className='form-control custom-margin-form-control'
                                    id='email'
                                    name="email"
                                    onChange={this.onChangeInput}
                                />
                                {EmailError}
                            </div>
                        </div>

                        <div className='row g-3 align-items-center mt-2'>
                            <div className="col-md-2">
                                <label htmlFor='mob' className="col-form-label"><strong>Mob#</strong></label>
                            </div>
                            <div className="col-md-10">
                                <PhoneInput
                                    country={'in'}
                                    value={this.state.phone}
                                    onChange={this.onChangePhone}
                                    inputClass=" custom-margin-form-control"
                                />

                                {/* <input
                                    type='tel'
                                    className='form-control custom-margin-form-control'
                                    id='mob'
                                    name="phone"
                                    onChange={this.onChangeInput}
                                /> */}
                                {MobError}
                            </div>
                        </div>

                        <div className='row g-3 align-items-center mt-2'>
                            <div className="col-md-2">
                                <label htmlFor='country' className="col-form-label"><strong>Country</strong></label>
                            </div>
                            <div className="col-md-10">
                                <input
                                    type='country'
                                    className='form-control custom-margin-form-control'
                                    id='country'
                                    name="country"
                                    onChange={this.onChangeInput}
                                    value={this.state.form.country}
                                />
                            </div>
                        </div>
                        <div className='row g-3 align-items-center mt-2'>
                            <strong className='text-danger'>* Mandatory</strong>
                        </div>

                        <div className='row mt-3'>
                            <Loader loading={this.state.loading} />
                        </div>
                        <div className='mt-3 p-3 text-center'>
                            <div className='mr-2'>
                                {/* <button
                                    type='submit'
                                    className='btn btn-primary mr-3 float-start'
                                    disabled={this.state.loading}
                                >Back</button> */}
                                <Link to='/login' className='btn btn-primary mr-3 inch-left'>Back</Link>
                                <button
                                    type='submit'
                                    className='btn btn-primary mr-3 inch-right'
                                    disabled={this.state.loading}
                                    onClick={this.onCickHandler}
                                >Next</button>
                                {/* <Link to='/foreword' className='btn btn-primary mr-3 inch-right' disabled={true}>Next</Link> */}
                            </div>
                        </div>
                    </form>
                </div>
                <Footer />
            </div>
        )
    }
}

export default Registration;
